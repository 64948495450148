import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getAgreements(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`agreements${query}`)
  },

  async storeAgreement(data) {
    return await apiInstance.post('agreements', data)
  },

  async getAgreementInfo(id) {
    return await apiInstance.get(`agreements/${id}`)
  },

  async updateAgreement(data) {
    return await apiInstance.put(`agreements/${data.id}`, data)
  },

  async deleteAgreement(data) {
    return await apiInstance.delete(`agreements/${data.id}`)
  },
}
